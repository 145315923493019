/* styles.css */

.grouped-data-container {
    margin-top: 20px;
  }
  
  .grouped-data-header {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .grouped-data-list {
    list-style: none;
    padding: 0;
  }
  
  .grouped-data-item {
    background-color: #f8f9fa; /* Bootstrap's default list item background color */
    border: 1px solid #dee2e6; /* Bootstrap's default border color */
    margin-bottom: 5px;
    padding: 10px;
  }
  
  .grouped-data-item a {
    color: #007bff; /* Bootstrap's primary link color */
    text-decoration: none;
  }
  
  .grouped-data-item a:hover {
    text-decoration: underline;
  }
  
  /* Set a different background color for the ul element */
  .grouped-data-list {
    background-color: #6c757d; /* Example background color */
  }
  
  /* Set a different background color for the li elements */
  .grouped-data-item {
    background-color: #172533; /* Example background color */
    border-color: #343a40; /* Example border color */
    color: #fff; /* Example text color */
  }
  