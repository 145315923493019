.hero {
  padding: 80px 30px 0px 30px;
  /* background-color: #fff; */
  position: relative;
  height: 450px;
  margin-top: 140px;
}

.slide-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.slide-content::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(16, 25, 46, 0.644);
  z-index: 2;
}

.hero-title {
  position: absolute;
  font-family: sans-serif;
  font-size: 22px;
  line-height: 20px;
  font-weight: 300;
  position: absolute;
  top: calc(100% / 2);
  text-align: center;
  padding: 0 30px;
  color: rgb(255, 255, 255);
  top: 50%;
  line-height: 25px;
  left: 50%;
  letter-spacing: 3px;
  text-transform: lowercase;
  transform: translate(-50%, -50%);
  z-index: 12;
  width: 100%;
}

.hero-title:first-letter {
  text-transform: uppercase;
}

.hero-image {
  height: 322px;
  width: 100%;
  object-fit: cover;
  -webkit-box-shadow: 1px 5px 15px #000;
  box-shadow: 1px 5px 15px #000;
  /* border-radius: 6px; */

  filter: grayscale(0);
}

@media (max-width: 500px) {
  .hero {
    height: 350px;
    padding: 40px 10px 0px 10px;
    margin-top: 180px;
  }
}
