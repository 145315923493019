/*======================================
 6. Home-style-3 
 =======================================*/

/* 6.1 wpo-features-section */

.wpo-features-section {
  padding: 40px 0;
  background: transparentize(#1e2e3e, 0.2);
  position: relative;
  z-index: 11;
  margin-top: -146px;

  @media (max-width: 991px) {
    margin-top: 0;
    background: $section-bg-dark;
    padding-bottom: 0;
  }

  .wpo-features-wrap {
    .custom-grid {
      @media (max-width: 575px) {
        float: left;
        width: 50%;
      }

      @media (max-width: 460px) {
        width: 100%;
      }
    }

    .features-item {
      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      @media (max-width: 460px) {
        text-align: center;
      }

      .features-icon {
        float: left;
        overflow: hidden;
        padding-right: 15px;
        margin-right: 15px;
        border-right: 1px solid #6c88a5;

        @media (max-width: 460px) {
          float: none;
          margin: 0;
          padding: 0;
          border: 0;
        }

        .fi {
          &::before {
            font-size: 40px;
            color: $theme-primary-color;
          }
        }
      }

      .features-text {
        overflow: hidden;

        span {
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          color: #aec5db;
        }

        h4 {
          font-weight: 400;
          font-size: 25px;
          line-height: 34px;
          color: $white;
          margin-top: 5px;
          margin-bottom: 0;

          @media (max-width: 1399px) {
            font-size: 19px;
            line-height: 35px;
          }

          @media (max-width: 475px) {
            font-size: 18px;
            line-height: 35px;
          }
        }
      }
    }
  }
}

/* 6.2 wpo-about-section-s3 */

.wpo-about-section-s3 {
  @include media-query(575px) {
    padding-top: 0px;
  }
}
.wpo-about-section-s3,
.wpo-about-section-s4 {
  .wpo-about-section-wrapper {
    .visible-text {
      right: auto;
      left: 0;
      top: -30px;

      @media (max-width: 1399px) {
        top: 20px;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    .wpo-about-content {
      padding-right: 40px;

      @media (max-width: 991px) {
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 40px;
        text-align: center;
      }
    }

    .wpo-about-img {
      position: relative;
      margin-left: 200px;

      @media (max-width: 1399px) {
        margin-left: 120px;
      }

      @media (max-width: 420px) {
        margin-left: 80px;
      }

      .about-img-inner {
        position: absolute;
        left: -190px;
        top: 50%;
        transform: translateY(-50%);
        max-width: 380px;
        padding: 15px;
        background-color: $section-bg-dark;
        border: 1px solid #786653;

        @media (max-width: 1399px) {
          max-width: 280px;
          left: -140px;
        }

        @media (max-width: 575px) {
          max-width: 220px;
          left: -110px;
        }

        @media (max-width: 420px) {
          max-width: 180px;
          left: -80px;
          padding: 8px;
        }
      }
    }
  }
}

/* 6.3 wpo-practice-section-s3 */

.wpo-practice-section-s3 {
  // background: $section-bg-dark;
  padding-bottom: 90px;

  @media (max-width: 991px) {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  @media (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  .wpo-practice-wrap {
    .wpo-practice-item {
      padding: 20px;
      border-radius: 12px;
      text-align: center;
      -webkit-box-shadow: 2px 2px 10px #000;
      box-shadow: 2px 2px 10px #000;
      // border: 1px solid #405D79;
      margin-bottom: 30px; 
      height: 390px;

      @media (max-width: 1199px) {
        padding: 10px;
      }

      .wpo-practice-icon {
        width: 110px;
        height: 110px;
        line-height: 110px;
        background: transparentize(#5580ab, 0.8);
        margin: 0 auto;
        border-radius: 50%;
        font-size: 50px;
        color: $theme-primary-color;
        margin-bottom: 30px;
      }

      .wpo-practice-content {
        h2 {
          font-weight: 400;
          font-size: 35px;
          line-height: 48px;
          margin-bottom: 20px;

          @media (max-width: 1199px) {
            font-size: 30px;
            margin-bottom: 15px;
          }

          a {
            color: #172533;

            &:hover {
              color: $theme-primary-color;
            }
          }
        }

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: $white;
          margin-bottom: 0;
        }
      }
    }
  }
}

/* 6.4 wpo-testimonials-section-s2 */

.wpo-testimonials-section-s2 {
  .wpo-testimonials-wrap {
    background: $section-bg-dark-2;
    padding: 0;

    .wpo-testimonials-active {
      .wpo-testimonials-item {
        padding: 0 85px;

        @media (max-width: 1399px) {
          padding: 0 30px;
        }

        @media (max-width: 991px) {
          padding: 60px 30px 80px;
        }

        @media (max-width: 575px) {
          padding: 40px 15px 60px;
        }

        p {
          margin-bottom: 40px;

          @media (max-width: 1399px) {
            font-size: 16px;
            line-height: 27px;
            margin-bottom: 20px;
          }
        }
      }

      .owl-dots {
        left: auto;
        top: auto;
        bottom: -70px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 1199px) {
          bottom: -25px;
        }

        @media (max-width: 991px) {
          bottom: 20px;
        }

        button {
          display: inline-block;
          margin-bottom: 0px;
          margin-right: 10px;
        }

        .owl-dot.active {
          background-color: $theme-primary-color;
        }
      }
    }
  }
}

/* 6.5 wpo-case-section-s2 */

.wpo-case-section-s2,
.wpo-case-section-s3 {
  .grid {
    .img-holder {
      position: relative;

      &:before {
        position: absolute;
        left: 2%;
        top: 1%;
        width: 96%;
        height: 98%;
      }
    }
  }

  .case-menu ul {
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 40px;
  }

  .case-grids {
    margin: 0 -7.5px;
  }

  .case-grids .grid {
    width: 33.33%;
    float: left;
    padding: 0 7.5px 15px;

    @media (max-width: 575px) {
      width: 100%;
    }

    .theme-btn-s2 {
      padding: 10px 25px;
    }
  }
}
