// wpo-page-title


.wpo-page-title {
	background: url(../../images/page-title.jpg) no-repeat center top / cover;
	min-height: 400px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-top: 150px;
	z-index: 1;

	@media(max-width: 767px) {
		min-height: 250px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #001635;
		content: "";
		z-index: -1;
		opacity: .30;
	}

	.wpo-breadcumb-wrap {

		h2 {
			color: #fff;
			margin-bottom: 20px;
			font-weight: 400;
			font-size: 50px;
			line-height: 69px;

			@media(min-width:991px){
				margin-top: -20px;
			}

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ol {
			padding-left: 0;
			margin-bottom: 0;

			li {
				display: inline-block;
				padding: 0px 5px;
				color: #fff;
				position: relative;
				font-weight: 400;
				font-size: 20px;
				line-height: 25px;

				@media(max-width:767px) {
					font-size: 18px;
				}

				&:after {
					content: "-";
					position: relative;
					left: 7px;
				}

				&:last-child {
					span {
						color: #cbd4fd;
					}

					&:after {
						display: none;
					}
				}

				a {
					color: #fff;
					font-size: 20px;
					transition: all .3s;

					&:hover {
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}