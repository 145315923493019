.logo_link {
  width: 50px;
  height: 50px;
}

.topbar-image {
  width: 100%;
  height: 100%;
  /* object-fit: contain;
  object-fit: cover; */
}

.topbar {
  padding: 15px 0 !important;
}
.row {
  display: flex;
}

.topbar-icon {
  width: 20px;
  height: 20px;
}

.topbar-phone {
  font-weight: 700;
  color: #009034;
}

.topbar-list {
  gap: 15px 40px;
  display: flex !important;
}

.topbar-button {
  padding: 6px 20px;
  font-size: 12px;
  border-radius: 6px;
  border: 2px solid #008251;
  background-color: #008251;
  outline: 0;
  cursor: pointer;
  color: #fff;
  margin-right: 5px;
}
.topbar-items {
  display: flex;
  align-items: center;
  gap: 10px;
}

.topbar-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topbar-link {
  font-weight: 700;
  color: black;
}

.topbar-links {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  color: black;
}

.topbar-item-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topbar-input {
  font-weight: 400;
  color: #000 !important;
  padding: 8px 12px;
  font-size: 12px;
  outline: none;
  line-height: normal !important;
  width: 250px;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0);
}

.topbar-country {
  vertical-align: top;
  line-height: 25px;
  color: #008251;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
}

.topbar-date {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #c49a6c;
}

@media (max-width: 1100px) {
  .topbar-input {
    width: 150px;
    font-size: 10px;
  }
  .topbar-button {
    padding: 6px 12px;
  }
  .topbar-link,
  .topbar-links {
    font-size: 12px;
  }
}

@media (max-width: 800px) {
  .topbar-item-lists,
  .topbar-date,
  .topbar-country,
  .topbar-items {
    display: none !important;
  }

  .topbar-image {
    width: 90px;
  }
  .topbar-list {
    display: flex;
    align-items: center;
  }
}
